
/* src/pages/home/Home.css */
/* For screens up to 768px (typical tablet and mobile screens) */
@media (max-width: 768px) {
    .content-section {
      flex-direction: column;
      align-items: center;
    }
  
    .left-section {
      max-width: 100%;
      align-items: center;
      text-align: center;
      margin-left: 20px;
      margin-right: 20px;
      padding: 20px; /* Add some padding for spacing */
    }
  
    .left-section img {
      max-width: 100%;
      margin-top: 20px; /* Reduce the margin for spacing */
    }
  
    .right-section {
      padding: 20px;
    }
  
    .icon-row {
      justify-content: center;
      margin-bottom: 10px; /* Reduce the margin for spacing */
    }
  }
  
.home-container {
    display: flex;
    flex-direction: column;
    background-color: white;

  }
  .social-link span {
    font-size: 14px; /* Adjust font size for better readability */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
  }
  
  .tabs {
    display: flex;
    justify-content: flex-end; /* Change to flex-end */
    gap: 30px;
   
    background-color: white;
  }
  
  .tab {
    padding: 15px 25px;
    font-size: 30px;
    background-color: white;
    border: none;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color:black;
    text-align: center;
    transition: background-color 0.3s ease, font-size 0.3s ease;
  }
  
  .tab:hover {
    background-color: #ddd;
  }

  .tab.selected {
    color:#750b35;
  }
  
  
  .content-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-left: 16px;
  }
  
  .left-section {
    padding-left: 20;
    display: flex;
    height: 100vh;
    max-width: 30%;
    background-color: #750b35;
    align-items: center;
    flex-direction: column;  
    
  }
  
  .left-section img {
    max-width: 70%;     
    margin-top: 60px;
    max-height: auto;  
  }
  
  .right-section {
    padding-right: 40px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .text {
    font-size: 36px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
   background: linear-gradient(to right, #750b35 0%, #750b35 100%);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
  }

/* Style the social links section */
.social-links {
    flex-direction: column;
    gap: 16px;
  }
  .icon-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end; 
    margin-top: auto; 
    margin-bottom: 20px; 
  }
  .icon-row img {
    margin: 5px; /* Add spacing around each icon */
  }
  /* Style each social link */
  .social-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black; 
    margin-top: 16px;
  }
  
  /* Remove underlines and color from links */
  .social-link a {
    text-decoration: none;
    color: black;
  }
  
  /* Style the icons */
  .social-link svg {
    font-size: 20px;
    margin-right: 8px;
  }
  
  /* Style the link text */
  .social-link span {
    font-size: 16px;
  }
  
  /* Apply the styles to visited links */
  .social-link a:visited {
    color: black;
  }
  
  